import {
  SUCCESS_GET_USER,
  GET_USER,
  ERROR_GET_USER,
  GET_USER_DATA,
  SUBMIT_INPUT,
  SUCCESS_DATA,
  SUCCESS_DATA2
} from "./action";

export const initialState = {
  apiBody: {},
  info: {
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    passwordConfirm: "",
    radio2: "a",
    radio5: "b",
    adminPassword: "",
    dan: "",
    routing: "",
    largestMonth: "",
    maxSingle: "",
    averageMonth: "",
    averageTrans: "",
    payTaken: "",
    goodSold: "",
    website: "",
    customerServicePhone: "",
    businessPhone: "",
    businessZip: "",
    businessState: "",
    businessCity: "",
    businessAddress2: "",
    businessAddress1: "",
    tp: "",
    dba: "",
    businessName: "",
    ownershipPercent: "",
    business1: "",
    ssn: "",
    dob: "",
    zip: "",
    state: "",
    city: "",
    address2: "",
    address1: ""
  },
  client: {},
  logo: {},
  favicon: {},
  footerLogo: {},
  base_url: "https://payroc.mojolicious.net",
  submitting: false,
  successData: {
    gatewayUsers: [
      {
        userName: null,
        password: null
      },
      {
        userName: null,
        password: null
      }
    ]
  },
  user:"",
  pas: ""
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER:
      return { ...state };
    case SUCCESS_DATA2:
      let ff = action.data;
      let fe= action.data1;
      console.log("ff" , ff)
      return { ...state, user:ff,
      pas: fe };
    case SUCCESS_DATA:
      let successData1 = action.data;
      let sub = action.sub;
      return { ...state,
        successData : successData1,
        submitting: sub };
    case SUBMIT_INPUT:
      return { ...state };
    case GET_USER_DATA:
      let values = action.data;
      let apiVals = action.apiVals;
      return { ...state, info: values, apiBody: apiVals };
    case SUCCESS_GET_USER:
      console.log("data", action.data);
      let clientInfo = action.data;
      let logoInfo = action.data.company_logo;
      let faviconInfo = action.data.company_favicon;
      let footer_logo = action.data.footer_logo;
      return { ...state, client: clientInfo, logo: logoInfo, favicon: faviconInfo, footerLogo: footer_logo };
    case ERROR_GET_USER:
      alert("error");
      return { ...state };
    default:
      return { ...state };
  }
}

export default reducer;
