import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getUser } from "../redux/clientConfiguration/action";

class Footer extends Component {
  render() {
    const { client, logo, base_url, footerLogo } = this.props || {};
    
    return (
      <footer
        className="site-footer section-grey"
        style={{ background: client.footer_color }}
      >
        <div className="container">
          <div className="column">
            <img
              src={base_url + footerLogo.url}
              style={{ maxWidth: "200px" }}
            />
          </div>
          <div className="column copyright">
            <p className="footer-copyright">{client.copyright}</p>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  client: state.users.client,
  logo: state.users.logo,
  base_url: state.users.base_url,
  footerLogo: state.users.footerLogo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
