import React, { Component } from "react";import Thanks from "./pages/thankyou";
import Form from "./pages/form";
import Home from "./pages/home"
import Confirm from "./pages/confirm"
import {Helmet} from "react-helmet"
//import {favicon} from "./img/cropped-favicon.png"
import {
  Switch,
  Route
} from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUser, submitInput } from "../src/redux/clientConfiguration/action";

//import './App.css';

class App extends Component  {
  render(){
  const { client, logo, base_url, footerLogo, apiBody, submitInput, successData,favicon } = this.props || {};
  const company = client.company_name
  return (
    <div>
      <Helmet>
        <title>
         {`${company} Registration - Powered by Payroc`}
        </title>
        {/* <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" /> */}
         <link rel="icon" sizes="192x192" href={base_url + favicon.url || null}/>
      </Helmet>
      <Switch>
        {/* If the current URL is /about, this route is rendered
            while the rest are ignored */}
        <Route path="/form">
          <Home />
        </Route>

        <Route path="/confirmation">
          <Confirm />
        </Route>
        {/* Note how these two routes are ordered. The more specific
            path="/contact/:id" comes before path="/contact" so that
            route will render when viewing an individual contact 
        <Route path="/contact/:id">
          <Contact />
        </Route>*/}
        <Route path="/thank-you">
          <Thanks />
        </Route>

        {/* If none of the previous routes render anything,
            this route acts as a fallback.

            Important: A route with path="/" will *always* match
            the URL because all URLs begin with a /. So that's
            why we put this one last of all */}
        <Route path="/">
          <Form />
        </Route>
      </Switch>
    </div>
  );
}}


const mapStateToProps = state => ({
  client: state.users.client,
  logo: state.users.logo,
  favicon: state.users.favicon,
  base_url: state.users.base_url,
  footerLogo: state.users.footerLogo,
  apiBody: state.users.apiBody,
  successData: state.users.successData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUser, submitInput }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (App);

//export default App;
