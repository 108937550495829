import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Pad from "../components/signaturePad";
import $ from "jquery";
import { getUser, submitInput } from "../redux/clientConfiguration/action";
import Footer from "../components/footer";
import Banner from "../components/banner";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Link, NavLink } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  myclick(a) {
    if (a) {
      this.props.submitInput(this.props.apiBody);
    } else {
      alert("Please agree to tearms and conditions");
    }
  }

  componentDidMount() {
    this.props.getUser();
  }

  resizeCanvas() {
    var canvas = document.getElementById("pad");

    setTimeout(function () {
      canvas.width = 364;
      canvas.height = 180;
    }, 100);
  }
  render() {
    const {
      client,
      logo,
      base_url,
      footerLogo,
      apiBody,
      submitInput,
      submitting,
    } = this.props || {};

    const hideActiveModal = () => {
      const modal = document.getElementsByClassName("modal show")[0];
      const fade = document.getElementsByClassName("modal-backdrop show")[0];
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove("modal-open");
      modal.className = modal.className.replace("show", "");
      fade.className = fade.className.replace("show", "");
    };
    return (
      <div>
        <Banner title={"Rates & Fees"} subtitle={"Terms & Conditions"} />

        <div className="container padding-bottom-3x mb-2">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-8">
              <div className="form-section-title">
                <h3>Rates and Fees</h3>
                <hr className="padding-bottom-1x" />
              </div>
              {client.company_name.includes("LabradorAI") ? (
                <>
                  {client.company_name === "LabradorAI Flat" ? (
                    <div>
                      <h4>Flat Rate</h4>
                      <div className="row">
                        <div className="col-sm-4">
                          <p>
                            Flat Rate % <span className="rateFee">2.90 %</span>
                          </p>
                        </div>
                        <div className="col-sm-8">
                          <p>
                            Flat Rate transaction fee{" "}
                            <span className="rateFee">$0.21</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {client.company_name === "LabradorAI RP" ? (
                    <div>
                      <h4>RewardPay</h4>
                      <div className="row">
                        <div className="col-sm-4">
                          <p>
                            Debit Card Interchange Plus{" "}
                            <span className="rateFee">0.30 %</span>
                          </p>
                        </div>
                        <div className="col-sm-8">
                          <p>
                            Debit Card Interchange Plus transaction fee{" "}
                            <span className="rateFee">$0.15</span>
                          </p>
                        </div>

                        <div className="col-sm-4">
                          <p>
                            % charged to the cardholder{" "}
                            <span className="rateFee">3.99%</span>
                          </p>
                        </div>
                        <div className="col-sm-8">
                          <p>
                            % charged to the merchant{" "}
                            <span className="rateFee">0%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {client.company_name === "LabradorAI IC" ? (
                    <div>
                      <h4>Interchange Plus</h4>
                      <div className="row">
                        <div className="col-sm-4">
                          <p>
                            Interchange Plus{" "}
                            <span className="rateFee">0.30 %</span>
                          </p>
                        </div>
                        <div className="col-sm-8">
                          <p>
                            Interchange Plus transaction fee{" "}
                            <span className="rateFee">$0.15</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div>
                  <h4>Visa/Mastercard/Discover</h4>
                  <div className="row">
                    {client.Qualified_discount_rate_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Qualified Discount Rate:{" "}
                          <span className="rateFee">
                            {parseFloat(client.qualified_discount_rate).toFixed(
                              2
                            )}
                            %
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {client.transaction_authorization_fee_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Trans/Auth Fee:{" "}
                          <span className="rateFee">
                            $
                            {parseFloat(
                              client.transaction_authorization_fee_number
                            ).toFixed(2)}
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {client.monthly_fee_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Monthly Fee:{" "}
                          <span className="rateFee">
                            ${parseFloat(client.monthly_fee_number).toFixed(2)}
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {client.mid_qualified_surcharge_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Mid-Qualified Surcharg:{" "}
                          <span className="rateFee">
                            {parseFloat(
                              client.mid_qualified_surcharge_number
                            ).toFixed(2)}
                            %
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {client.non_qualified_surcharge_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Non-Qualified Surcharge:{" "}
                          <span className="rateFee">
                            {parseFloat(
                              client.non_qualified_surcharge_number
                            ).toFixed(2)}
                            %
                          </span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-section-title">
                    <h4>American Express Opt Blue </h4>
                  </div>
                  <div className="row">
                    {client.amex_qualified_rate_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Qualified Rate:{" "}
                          <span className="rateFee">
                            {parseFloat(client.amex_qualified_rate).toFixed(2)}%
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {client.amex_transaction_authorization_fee_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Trans/Auth Fee:{" "}
                          <span className="rateFee">
                            $
                            {parseFloat(
                              client.amex_transaction_authorization_fee_number
                            ).toFixed(2)}
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {client.midqualified_surcharge_number_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Mid-Qualified Surcharge (%):
                          <span className="rateFee">
                            {parseFloat(
                              client.midqualified_surcharge_number
                            ).toFixed(2)}
                            %
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {client.nonqualified_surcharge_boolean ? (
                      <div className="col-sm-4">
                        <p>
                          Non-Qualified Surcharge (%):
                          <span className="rateFee">
                            {parseFloat(
                              client.nonqualified_surcharge_number
                            ).toFixed(2)}
                            %
                          </span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="form-section-title">
                <h3>Terms &amp; Conditions</h3>
                <hr className="padding-bottom-1x" />
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <div
                      className="custom-control custom-checkbox"
                      style={{ marginLeft: "0px" }}
                    >
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="same_address"
                        onChange={() => {
                          this.setState({ checked: !this.state.checked });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        for="same_address"
                      >
                        I Agree to the{" "}
                        <a
                          href="https://www.payroc.com/merchant-terms-conditions/"
                          target="_blank"
                        >
                          Merchant Processing Agreement Terms and Conditions
                        </a>
                        .
                      </label>
                      {client.company_name === "LabradorAI RP" ||
                      client.company_name === "LabradorAI Flat" ||
                      client.company_name === "LabradorAI IC" ? (
                        <p>
                          <i>No Contract or Termination Fees</i>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-section-title">
                <h3>Acknowledge &amp; Submit</h3>
                <hr className="padding-bottom-1x" />
              </div>
              <p>
                By clicking the accept button below, you acknowledge that you
                have received, read, and agree to be bound by, and are attaching
                your electronic signature to the Merchant Processing Agreement
                and applicable Payment Receipt.
              </p>
              <div className="form-footer">
                {/* <a
                    className="btn btn-lg btn-rounded btn-secondary"
                    href="documents/Application.pdf"
                  >
                    Print and Save
                  </a> */}
                {/* <button
                    className="btn btn-lg btn-rounded btn-primary"
                    data-toggle="modal"
                    data-target="#signature"
                    onClick={this.resizeCanvas}
                    type="submit"
                  > */}

                {submitting ? (
                  <a className="btn btn-lg btn-rounded btn-primary" disabled>
                    Accept and Continue
                  </a>
                ) : (
                  <button
                    className="btn btn-lg btn-rounded btn-primary"
                    onClick={() => this.myclick(this.state.checked)}
                    type="button"
                  >
                    Accept and Continue
                  </button>
                )}
                {/* <Loader leading={submitting}/> */}
                {submitting ? (
                  <div style={{ textAlign: "center" }}>
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <br />
                    <p style={{ color: "#666" }}>
                      Submission might take a few minutes
                    </p>
                    <br /> <b>DO NOT CLOSE THE BROWSER</b>
                  </div>
                ) : null}

                {/* <div style={{ paddingTop: "20px" }}>
                    Reference ID: 443413&nbsp;&nbsp;&nbsp;
                  </div> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div
          className="modal fade"
          id="signature"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Draw your signature
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ height: "350px" }}>
                <Pad />
                <br />
                <small>
                  By selecting Sign and Complete, I agree that the signature
                  will be the electronic representation of my signature - just
                  the same as a pen-and-paper signature.
                </small>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Clear
                </button>
                <Link to="/thank-you" onClick={hideActiveModal}>
                  <a className="btn btn-primary">Sign and Complete</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.users.client,
  logo: state.users.logo,
  base_url: state.users.base_url,
  footerLogo: state.users.footerLogo,
  apiBody: state.users.apiBody,
  submitting: state.users.submitting,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUser, submitInput }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
