import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getUser } from "../redux/clientConfiguration/action";

class Banner extends Component {
  render() {
    const { client, logo, base_url, footerLogo, title, subtitle } =
      this.props || {};
    return (
      <div
        className={
          "page-title section-grey section-blue lp-banner " +
          (client.banner_shape === "curve" ? null : "lp-banner1")
        }
        style={{ background: client.banner_color }}
      >
        <div className="container">
          <div className="column">
            <a href="/">
              <img src={base_url + logo.url} style={{ maxWidth: "390px" }} />
            </a>
          </div>
          <div className="column" />
        </div>
        <div className="container lp-content">
          <h1 style={{ textAlign: "center" }}>
            {title || client.banner_title}
          </h1>
          <p>{subtitle || client.banner_subtitle}</p>

          <img
            src="/img/down-arrow-icon.png"
            alt="down-arrow-icon"
            style={{ width: "50px" }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.users.client,
  logo: state.users.logo,
  base_url: state.users.base_url,
  footerLogo: state.users.footerLogo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUser }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
