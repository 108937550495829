import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import history from '../../src/history';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUser, getUserData } from "../redux/clientConfiguration/action";
import Footer from "../components/footer";
import Banner from "../components/banner";
import NumberFormat from 'react-number-format';
import CurrencyFormat from 'react-currency-format';


// class MyNumberInput extends React.Component {
//   state = {
//     value: '',
//   };
//   render() {
//     return (
//       <NumberFormat
//         placeholder="Number Format Input looses focus"
//         isNumericString={true}
//         thousandSeparator={true}
//         value={this.state.value}
//         onValueChange={vals => this.setState({ value: vals.formattedValue })}
//         {...this.props}
//       />
//     );
//   }
// }

class Form extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  // shownew() {
  //   alert()
  // }

  render() {
    const { client, logo, base_url, footerLogo, info } = this.props || {};
    const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const websiteRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    const passwordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
    const ssnRegex = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;
    const tpRegex = /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/
    
    return (
      <div>
        <Banner />
        <div className="container padding-bottom-3x mb-2">
          <Formik
            initialValues={{
              email:info.email,
              firstName: info.firstName,
              middleName: info.middleName,
              lastName: info.lastName,
              passwordConfirm: info.passwordConfirm,
              radio2: '501c',
              radio5: info.radio5,
              adminPassword: info.adminPassword,
              dan: info.dan,
              routing: info.routing,
              largestMonth: info.largestMonth,
              maxSingle: info.maxSingle,
              averageMonth: info.averageMonth,
              averageTrans: info.averageTrans,
              payTaken: info.payTaken,
              goodSold: info.goodSold,
              website: info.website,
              customerServicePhone: info.customerServicePhone,
              businessPhone: info.businessPhone,
              businessZip: info.businessZip,
              businessState: info.businessState,
              businessCity: info.businessCity,
              businessAddress2: info.businessAddress2,
              businessAddress1: info.businessAddress1,
              tp: info.tp,
              dba: info.dba,
              businessName: info.businessName,
              ownershipPercent: 0,
              business1: info.business1,
              ssn: info.ssn,
              dob: info.dob,
              zip: info.zip,
              state: info.state,
              city: info.city,
              address2: info.address2,
              address1: info.address1,
              quantity: 0,
              payTaken1: false
            }}
             validationSchema={Yup.object().shape({
              firstName: Yup.string().required("Required"),
              email: Yup.string().email().required("Required"),
              lastName: Yup.string().required("Required"),
              address1: Yup.string().required("Required"),
              city: Yup.string().required("Required"),
              state: Yup.string().required("Required"),
              zip: Yup.string()
                .matches(zipRegex, "Incorrect ZipCode")
                .required("Required"),
              dob: Yup.date().required("Required"),
              ssn: Yup.string()
                .matches(ssnRegex, "Incorrect Social Security Number"),
              ownershipPercent: Yup.number()
                .min(0)
                .max(
                  100,
                  "Ownership Percentage must be less than or equal to 100%"
                ),
              businessName: Yup.string().required("Required"),
              tp: Yup.string()
                .matches(tpRegex, "Invalid EIN")
                .required("Required"),
              businessAddress1: Yup.string().required("Required"),
              businessCity: Yup.string().required("Required"),
              businessState: Yup.string().required("Required"),
              businessZip: Yup.string()
                .matches(zipRegex, "Incorrect ZipCode")
                .required("Required"),
              businessPhone: Yup.string()
                .matches(phoneRegex, "Phone number is not valid")
                .required("Required"),
              //goodSold: Yup.string().required("Required"),    ****** CHECK IF OKAY KEVIN
              maxSingle: Yup.string()
                .required("Required"),
              largestMonth: Yup.string()
                .required("Required"),
              routing: Yup.string()
                .length(9, "Invalid Routing Number")
                .required("Required"),
              dan: Yup.number()
                .positive()
                .integer("Only numbers please")
                .required("Required")
            })}  
            onSubmit={async values => {
              await new Promise(resolve => setTimeout(resolve, 500));
              //alert(JSON.stringify(body, null, 2));
              let body = {
                DBAName: values.dba.replace(/'/g, '') || values.businessName.replace(/'/g, ''),
                LegalName: values.businessName.replace(/'/g, ''),
                DBAAddress: values.businessAddress1,
                DBACity: values.businessCity,
                DBAZip: values.businessZip,
                DBAState: values.businessState,
                DBATelephone: values.businessPhone.replace(/-|\s/g,""),
                ACHCheckingAccountNumber1: values.dan,
                ACHTransitNumberABA1: values.routing,
                Principal1FirstName: values.firstName,
                Principal1LastName: values.lastName,
                Principal1SSN: values.ssn,
                Principal1Email: values.email,
                Principal1Address: values.address1,
                Principal1City: values.city,
                Principal1ZipCode: values.zip,
                Principal1State: values.state,
                Principal1Equity: values.ownershipPercent,
                Principal1DateofBirth: values.dob,
                FedTaxID: values.tp.replace(/-|\s/g,""),
                SendEmailVerification: true,
                ProductsServicesSold: values.goodSold,
                HighTicketAmount: values.maxSingle.replace(/,|\$/g,""),
                HighMonthlyVolume: values.largestMonth.replace(/,|\$/g,""),
                Business_TypeOfBusiness: values.radio2,
                BusinessType:values.payTaken1 ? "Retail" : "Online",
                Shipping: values.payTaken1 ? "On" : "Off" ,
                TerminalType2Quantity: values.quantity,
                TerminalType2:"iPS",
                PaymentTerms: "Sale",
                ShippingName: values.firstName + ' ' + values.lastName,
                ShippingPhone: values.businessPhone.replace(/-|\s/g,""),
                ShippingAddress1: values.businessAddress1,
                ShippingCity: values.businessCity,
                ShippingState: values.businessState,
                ShippingZIP: values.businessZip,
                ERFComments: "$65 each, tax and shipping included. Will be billed within 60 days of order submission."
              };
              //alert(JSON.stringify(body, null, 2));
              console.log("values.tp" + values.tp)
              this.props.getUserData(values, body);
              history.push("/form")
            }}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-8">
                  

                      <div className="form-section-title">
                        <h3>Tell Us About Your Business</h3>
                        <hr className="padding-bottom-1x" />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>
                              Check appropriate box for federal tax
                              classification of the business.
                            </label>
                            <br />

                            {client.company_name === "LabradorAI RP" ||
                            client.company_name === "LabradorAI Flat" ||
                            client.company_name === "LabradorAI IC" ? null:
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id="payroc-business-type-7"
                                name="radio2"
                                checked={values.radio2 === "501c"}
                                onChange={() => setFieldValue("radio2", "501c")}
                              />
                              <label
                                className="custom-control-label"
                                for="payroc-business-type-7"
                              >
                                Non-Profit
                              </label>
                            </div>
            }
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                name="radio2"
                                id="payroc-business-type-1"
                                checked={values.radio2 === "soleproprietor"}
                                onChange={() => setFieldValue("radio2", "soleproprietor")}
                              />
                              <label
                                className="custom-control-label"
                                for="payroc-business-type-1"
                              >
                                Individual/Sole Proprietorship/Single-Member LLC
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                name="radio2"
                                id="payroc-business-type-2"
                                checked={values.radio2 === "partnership"}
                                onChange={() => setFieldValue("radio2", "partnership")}
                              />
                              <label
                                className="custom-control-label"
                                for="payroc-business-type-2"
                              >
                                Partnership
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id="payroc-business-type-3"
                                name="radio2"
                                checked={values.radio2 === "c-corporation"}
                                onChange={() => setFieldValue("radio2", "c-corporation")}
                              />
                              <label
                                className="custom-control-label"
                                for="payroc-business-type-3"
                              >
                                C Corporation
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id="payroc-business-type-4"
                                name="radio2"
                                checked={values.radio2 === "s-corporation"}
                                onChange={() => setFieldValue("radio2", "s-corporation")}
                              />
                              <label
                                className="custom-control-label"
                                for="payroc-business-type-4"
                              >
                                S Corporation
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id="payroc-business-type-5"
                                name="radio2"
                                checked={values.radio2 === "llc"}
                                onChange={() => setFieldValue("radio2", "llc")}
                              />
                              <label
                                className="custom-control-label"
                                for="payroc-business-type-5"
                              >
                                Limited Liability Company (LLC)
                              </label>
                            </div>
                            {client.company_name === "LabradorAI RP" ||
                            client.company_name === "LabradorAI Flat" ||
                            client.company_name === "LabradorAI IC" ? null:
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id="payroc-business-type-6"
                                name="radio2"
                                checked={values.radio2 === "government"}
                                onChange={() => setFieldValue("radio2", "government")}
                              />
                              <label
                                className="custom-control-label"
                                for="payroc-business-type-6"
                              >
                                Government
                              </label>
                            </div>
            }
                            
                          </div>
                        </div>
                      </div>
                      <div className="row">
                      
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="businessName">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Legal Business Name&nbsp;
                              {/* <i
                                className="pe-7s-help1"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-trigger="hover"
                                title=""
                                data-content="Please provide your full legal first name."
                                data-original-title="Legal Business Name"
                              /> */}
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="businessName"
                              placeholder=""
                              value={values.businessName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.businessName &&
                              touched.businessName && (
                                <div className="input-feedback">
                                  {errors.businessName}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="dba">
                            <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Business DBA Name&nbsp;
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="dba"
                              placeholder={values.businessName}
                              value={values.dba}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.dba &&
                              touched.dba && (
                                <div className="input-feedback">
                                  {errors.dba}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="tp">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Taxpayer Identification Number
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="tp"
                              placeholder=""
                              value={values.tp}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.tp &&
                              touched.tp && (
                                <div className="input-feedback">
                                  {errors.tp}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label for="businessAddress1">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Street Address
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="businessAddress1"
                              placeholder=""
                              value={values.businessAddress1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.businessAddress1 &&
                              touched.businessAddress1 && (
                                <div className="input-feedback">
                                  {errors.businessAddress1}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="businessCity">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              City
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="businessCity"
                              placeholder=""
                              value={values.businessCity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.businessCity &&
                              touched.businessCity && (
                                <div className="input-feedback">
                                  {errors.businessCity}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label for="businessState">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              State
                            </label>
                            <select
                              className="form-control form-control-square"
                              id="businessState"
                              value={values.businessState}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label for="businessZip">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Zip Code
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="businessZip"
                              placeholder=""
                              value={values.businessZip}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.businessZip &&
                              touched.businessZip && (
                                <div className="input-feedback">
                                  {errors.businessZip}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="businessPhone">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Business Phone Number
                            </label>

                            <NumberFormat 
                            className="form-control form-control-square"
                            id="businessPhone"
                            value={values.businessPhone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            displayType={'input'}
                            format="### ###-####" 
                            mask="_"/>
                            {/* <input
                              className="form-control form-control-square"
                              type="text"
                              id="businessPhone"
                              value={values.businessPhone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            /> */}
                            {errors.businessPhone &&
                              touched.businessPhone && (
                                <div className="input-feedback">
                                  {errors.businessPhone}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-section-title">
                        <h3>Tell us about your sales</h3>
                        <hr className="padding-bottom-1x" />
                      </div>
                      <div className="row">
                        {!client.hide_type_of_business ? 
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="goodSold">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Type of Business / Goods Sold
                            </label>
                            <select
                              className="form-control form-control-square"
                              id="goodSold"
                              placeholder=""
                              value={values.goodSold}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select</option>
                              <option>
                                Charitable & Social Service Organizations
                              </option>
                              <option>Political Organizations</option>
                              <option>
                                Civil, Social & Fraternal Associations
                              </option>
                              <option>
                                Schools & Educational Services
                              </option>
                              <option>Health & allied Services</option>
                              <option>Colleges & Universities</option>
                              <option>
                                Membership Sports & Recreation Clubs
                              </option>
                              <option>
                                Business Services - Not Elsewhere
                                Classified
                              </option>
                              <option>
                                Management, Consulting & PR Services
                              </option>
                              {/* <option>Misc. Personal Services</option>
                              <option>Misc. Retail Stores</option>
                              <option>Insurance Carriers</option>
                              <option>
                                Computer Network/Information Services
                              </option> */}
                            </select>

                            {errors.goodSold &&
                              touched.goodSold && (
                                <div className="input-feedback">
                                  {errors.goodSold}
                                </div>
                              )}
                          </div>
                        </div>
                        :null}

{!client.hide_how_are_payments_taken ? 
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="payTaken">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              How are payments taken (check all that apply)
                            </label>
                            <br />
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="payTaken2"
                                value={values.payTaken2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked
                              />
                              <label
                                className="custom-control-label"
                                for="payTaken2"
                              >
                                Online
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="payTaken1"
                                value={values.payTaken1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.payTaken1 &&
                                touched.payTaken1 && (
                                  <div className="input-feedback">
                                    {errors.payTaken1}
                                  </div>
                                )}
                              <label
                                className="custom-control-label"
                                for="payTaken1"
                              >
                                In-Person w/Card
                              </label>
                            </div>
                          </div>
                        </div>
                      
                      :null}
                      </div>
                      <div className="row">
                        {/* <div className="col-sm-3">
                          <div className="form-group">
                            <label for="averageTrans">
                              Average Transaction ($)
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="averageTrans"
                              placeholder=""
                              value={values.averageTrans}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.averageTrans &&
                              touched.averageTrans && (
                                <div className="input-feedback">
                                  {errors.averageTrans}
                                </div>
                              )}
                          </div>
                        </div> */}
                        {/* <div className="col-sm-3">
                          <div className="form-group">
                            <label for="averageMonth">
                              Average Monthly Sales ($)
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="averageMonth"
                              placeholder=""
                              value={values.averageMonth}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.averageMonth &&
                              touched.averageMonth && (
                                <div className="input-feedback">
                                  {errors.averageMonth}
                                </div>
                              )}
                          </div>
                        </div> */}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="maxSingle">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Maximum Single Transaction ($)
                            </label>

                            <NumberFormat 
                             className="form-control form-control-square"
                             type="text"
                             id="maxSingle"
                             placeholder=""
                             value={values.maxSingle}
                             onChange={handleChange}
                             onBlur={handleBlur}
                            displayType={'input'} 
                            thousandSeparator={true}
                            prefix={'$'}/>

                            {/* <input
                              className="form-control form-control-square"
                              type="text"
                              id="maxSingle"
                              placeholder=""
                              value={values.maxSingle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                     */}
                            {errors.maxSingle &&
                              touched.maxSingle && (
                                <div className="input-feedback">
                                  {errors.maxSingle}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="largestMonth">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Largest Expected Monthly Sales ($)
                            </label>

                            <NumberFormat 
                             className="form-control form-control-square"
                             type="text"
                             id="largestMonth"
                             placeholder=""
                             value={values.largestMonth}
                             onChange={handleChange}
                             onBlur={handleBlur}
                            displayType={'input'} 
                            thousandSeparator={true}
                            prefix={'$'}/>

                            {errors.largestMonth &&
                              touched.largestMonth && (
                                <div className="input-feedback">
                                  {errors.largestMonth}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-section-title">
                        <h3>Tell us where to deposit your money</h3>
                        <hr className="padding-bottom-1x" />
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="routing">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Deposit Routing Number{" "}
                              
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="routing"
                              placeholder=""
                              value={values.routing}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.routing &&
                              touched.routing && (
                                <div className="input-feedback">
                                  {errors.routing}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="dan">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Deposit Account Number{" "}
                              {/* <i
                                className="pe-7s-help1"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-trigger="hover"
                                data-html="true"
                                data-content="<img src='https://www.sdccu.com/getmedia/f649d1f1-2a55-49b7-ac70-48372bb0f8cc/Checking-101.png.aspx'/>"
                                data-original-title="Account Number"
                              /> */}
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="dan"
                              placeholder=""
                              value={values.dan}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.dan &&
                              touched.dan && (
                                <div className="input-feedback">
                                  {errors.dan}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <p>
                              <i>
                                <b>Note:</b> Some financial institutions use a
                                non-standard format in this series of numbers. Please
                                disregard the symbols and just enter the
                                numbers.
                              </i>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="form-section-title">
                        <h3>Tell Us About You</h3>
                        <hr className="padding-bottom-1x" />
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="firstName">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              First Name{" "}
                            
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="firstName"
                              placeholder=""
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.firstName &&
                              touched.firstName && (
                                <div className="input-feedback">
                                  {errors.firstName}
                                </div>
                              )}
                          </div>
                        </div>
                        
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="lastName">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Last Name&nbsp;
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="lastName"
                              placeholder=""
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.lastName &&
                              touched.lastName && (
                                <div className="input-feedback">
                                  {errors.lastName}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group"><sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                            <label for="email">Email</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="email"
                              placeholder=""
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email &&
                              touched.email && (
                                <div className="input-feedback">
                                  {errors.email}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="address1">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Street Address
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="address1"
                              placeholder=""
                              value={values.address1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.address1 &&
                              touched.address1 && (
                                <div className="input-feedback">
                                  {errors.address1}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="city">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              City
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="city"
                              placeholder=""
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.city &&
                              touched.city && (
                                <div className="input-feedback">
                                  {errors.city}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="state">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              State
                            </label>
                            <select
                              className="form-control form-control-square"
                              id="state"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                          {errors.state &&
                            touched.state && (
                              <div className="input-feedback">
                                {errors.state}
                              </div>
                            )}
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label for="zip">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Zip Code
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="zip"
                              placeholder=""
                              value={values.zip}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.zip &&
                              touched.zip && (
                                <div className="input-feedback">
                                  {errors.zip}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label for="dob">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                              Date of Birth
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="date"
                              id="dob"
                              value=""
                              value={values.dob}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.dob &&
                              touched.dob && (
                                <div className="input-feedback">
                                  {errors.dob}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-section-title">
                        {values.radio2 === "government" || values.radio2 === "501c" ? (
                          <h3>Officer / Authorized Signer</h3>
                        ) : (
                          <h3>Owner / Certification of Beneficial Owner(s)</h3>
                        )}
                        <hr className="padding-bottom-1x" />
                      </div>
                      <p>
                        Federal Beneficial Owner(s) regulations require
                        information for each individual who owns 25% or more of
                        the legal entity or an individual responsible for
                        managing the legal entity (e.g., Chief Executive
                        Officer, Chief Financial Officer, Managing Member,
                        General Partner, President, Vice President, Treasurer).
                      </p>
                      <br />
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="nameDontChange">Full Name</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="nameDontChange"
                              disabled
                              value={
                                values.firstName +
                                " " +
                                values.middleName +
                                " " +
                                values.lastName
                              }
                            />
                          </div>
                        </div>
                        {(values.radio2 === "501c" ||
                        values.radio2 === "government")&&
                        !client.percentage_requiered ? null : (
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label for="ownershipPercent">
                              <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}
                                Ownership Percentage (%)&nbsp;
                              </label>
                              <input
                                className="form-control form-control-square"
                                type="number"
                                id="ownershipPercent"
                                value={values.ownershipPercent}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                
                              />
                              {errors.ownershipPercent &&
                                touched.ownershipPercent && (
                                  <div className="input-feedback">
                                    {errors.ownershipPercent}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                        {(values.radio2 === "501c" ||
                        values.radio2 === "government") &&
                        !client.ssn_requiered ? null : (
                          <div className="col-sm-4">
                            <div className="form-group">
                              
                              <label for="ssn">  <sup
                                className="required"
                                style={{ color: "red" }}
                              >
                                *
                              </sup>{" "}Social Security Number</label>
                              <input
                                className="form-control form-control-square"
                                type="text"
                                id="ssn"
                                value="xxx-xx-xxxx"
                                value={values.ssn}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                              {errors.ssn &&
                                touched.ssn && (
                                  <div className="input-feedback">
                                    {errors.ssn}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                       
                      </div>

                      {/* <div className="row onecl" style={{display:"none"}}>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="nameDontChange">Full Name</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="nameDontChange"
                              
                              value={
                                values.firstName +
                                " " +
                                values.middleName +
                                " " +
                                values.lastName
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="ssn1">Social Security Number</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="ssn1"
                              value="xxx-xx-xxxx"
                              value={values.ssn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.ssn &&
                              touched.ssn && (
                                <div className="input-feedback">
                                  {errors.ssn}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="ownershipPercent1">
                              Ownership Percentage&nbsp;<i
                                className="pe-7s-help1"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-trigger="hover"
                                title=""
                                data-content="Please provide your full legal last name."
                                data-original-title="Ownership Percentage"
                              />
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="number"
                              id="ownershipPercent1"
                              value="100"
                              value={values.ownershipPercent}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.ownershipPercent &&
                              touched.ownershipPercent && (
                                <div className="input-feedback">
                                  {errors.ownershipPercent}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row onecl2" style={{display:"none"}}>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="nameDontChange">Full Name</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="nameDontChange"
                              
                              value={
                                values.firstName +
                                " " +
                                values.middleName +
                                " " +
                                values.lastName
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="ssn2">Social Security Number</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="ssn2"
                              value="xxx-xx-xxxx"
                              value={values.ssn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.ssn &&
                              touched.ssn && (
                                <div className="input-feedback">
                                  {errors.ssn}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="ownershipPercent2">
                              Ownership Percentage&nbsp;<i
                                className="pe-7s-help1"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-trigger="hover"
                                title=""
                                data-content="Please provide your full legal last name."
                                data-original-title="Ownership Percentage"
                              />
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="number"
                              id="ownershipPercent2"
                              value="100"
                              value={values.ownershipPercent}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.ownershipPercent &&
                              touched.ownershipPercent && (
                                <div className="input-feedback">
                                  {errors.ownershipPercent}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row onecl3" style={{display:"none"}}>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="nameDontChange">Full Name</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="nameDontChange"
                              
                              value={
                                values.firstName +
                                " " +
                                values.middleName +
                                " " +
                                values.lastName
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="ssn3">Social Security Number</label>
                            <input
                              className="form-control form-control-square"
                              type="text"
                              id="ssn3"
                              value="xxx-xx-xxxx"
                              value={values.ssn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.ssn &&
                              touched.ssn && (
                                <div className="input-feedback">
                                  {errors.ssn}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label for="ownershipPercent3">
                              Ownership Percentage&nbsp;<i
                                className="pe-7s-help1"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-trigger="hover"
                                title=""
                                data-content="Please provide your full legal last name."
                                data-original-title="Ownership Percentage"
                              />
                            </label>
                            <input
                              className="form-control form-control-square"
                              type="number"
                              id="ownershipPercent3"
                              value="100"
                              value={values.ownershipPercent}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.ownershipPercent &&
                              touched.ownershipPercent && (
                                <div className="input-feedback">
                                  {errors.ownershipPercent}
                                </div>
                              )}
                          </div>
                        </div>
                      </div> */}
                      {/* <a style={{ textDecoration: "none" }}>
                        + Add Additional Owner
                      </a> */}
                      <div className="form-group">
                        <div className="custom-control custom-checkbox" />
                      </div>

                      {values.payTaken1 ? (
                        <div>
                          <div className="form-section-title">
                            <h3>Request a card reader</h3>
                            <hr className="padding-bottom-1x" />
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id="payroc1"
                                  name="radio5"
                                  checked={values.radio5 === "a"}
                                  onChange={() => setFieldValue("radio5", "a")}
                                />
                                <label
                                  className="custom-control-label"
                                  for="payroc1"
                                >
                                  Yes, I would like to purchase a reader<br />
                                  {client.retail_text?  <small>
                                  $65 each, tax and shipping included. Will be billed within 60 days of order submission.
                                  </small>
                                  :null}
                                
                                </label>
                              </div>
                              {values.radio5 === "a" ? 
                                <div className="custom-control custom-control-inline">
                                 
                                  <label
                                    for="payroc-business-type-8"
                                    style={{ paddingRight: "10px" }}
                                  >
                                     Quantity: 
                                  </label>
            
                                  <input
                                    className="form-control form-control-square"
                                    type="number"
                                    min="1" max="100"
                                    id="quantity"
                                    className="form-control form-control-square"
                                     value={values.quantity}
                                     onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                               : null}

                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id="payroc-business-type-99"
                                  name="radio5"
                                  checked={values.radio5 === "b"}
                                  onChange={() => setFieldValue("radio5", "b")}
                                />
                                <label
                                  className="custom-control-label"
                                  for="payroc-business-type-99"
                                >
                                  No Thank You, I already have compatible equipment.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-footer">
                        <button
                          type="submit"
                          className="btn btn-lg btn-rounded btn-primary"
                          style={{
                            background: client.primaryButton_color,
                            border: client.primaryButton_color
                          }}
                        >
                          Continue
                        </button>

                        {/* <div style={{ paddingTop: "20px" }}>
                          Reference ID: 443413&nbsp;&nbsp;&nbsp;
                        </div> */}
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  client: state.users.client,
  logo: state.users.logo,
  base_url: state.users.base_url,
  footerLogo: state.users.footerLogo,
  info: state.users.info,
  successData: state.users.successData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUser, getUserData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
