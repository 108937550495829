import React, { Component } from "react";
import Footer from "../components/footer";
import Banner from "../components/banner";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { submitQuery, getUser } from "../redux/clientConfiguration/action";
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('id');

class Confirm extends Component {
  componentDidMount() {
    
    if (myParam) {
      //alert(myParam)
      this.props.submitQuery(myParam)
    }
    this.props.getUser();
  }


  render() {
    const {
      successData,
      user,
      pas
    } =
      this.props || {};
      console.log("sss",successData)
    return (
      <div>
        <Banner
          title={"Configuring Online Payment Processing"}
          subtitle={
            "Follow the steps below to activate your new Payroc Merchant Account within the MemberClicks Portal."
          }
        />
        <div class="container padding-bottom-3x mb-2">
          <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-8">
              <p>
                Through the <b>Payment Service Options</b> area, you're able to
                integrate with Payroc real-time credit card and e-check payment
                solutions. Real-time credit card processing allows a user to pay
                their amount due with a credit card instantly. E-check
                processing allows a user to pay their amount due with a checking
                account by entering the routing and account numbers.{" "}
              </p>

              <h3 style={{ paddingTop: "30px" }}>
                Step 1: Access Payment Processors in Memberclicks Portal
              </h3>
              <p>
                Navigate to{" "}
                <b>Financial&nbsp;&nbsp;&gt;&nbsp;&nbsp;Invoicing</b>, then
                click on <b>Payment Processors</b> in the top righthand corner.
              </p>
              <img src="img/STEP-1.png" />

              <h3 style={{ paddingTop: "30px" }}>
                Step 2: Add a New Payment Processor
              </h3>
              <p>
                Click the <b>Add New Payment Processor</b> button to display the{" "}
                <b>Add A Payment Processor</b> window.
              </p>
              <img src="img/STEP-2.png" />

              <h3 style={{ paddingTop: "30px" }}>
                Step 3: Enter your Payroc Credentials
              </h3>
              <div class="row">
                <div class="col-lg-8">
                  <p>Complete the form using the information below.</p>
                  <ul>
                    <li>
                      <b>Label:&nbsp;&nbsp;</b> Payroc
                    </li>
                    <li>
                      <b>Processor:&nbsp;&nbsp;</b> Payroc
                    </li>
                    <li>
                      <b>Login:&nbsp;&nbsp;</b> <i>{successData.gatewayUsers[1].userName}{user}</i>
                    </li>
                    <li>
                      <b>Security Key:&nbsp;&nbsp;</b> <i>{successData.gatewayUsers[1].password}{pas}</i>
                    </li>
                    <li>
                      <b>Credit Cards:&nbsp;&nbsp;</b> Select each credit card
                      type that you’re able to accept <br />
                      <i>
                        (NOTE: Only select Amex or Discover if your Payroc
                        account is configured to allow for them).
                      </i>
                    </li>
                  </ul>
                </div>
                {/* <div class="col-lg-4">
                  <div class="payrocHelp">
                    <p>
                      Don't have your Payroc<br />Login &amp; Security Key?
                    </p>
                    <a
                      class="btn btn-lg btn-rounded btn-primary"
                      href="https://www.memberclicks.com"
                    >
                      Click Here
                    </a>
                  </div>
                </div> */}
              </div>
              <img src="img/STEP-3.png" />

              <h3 style={{ paddingTop: "30px" }}>Step 4: Save</h3>
              <p>
                Click <b>Save</b> to complete the activation of your Payroc
                Merchant Account within Memberclicks.
              </p>
              <p>
                Oasis automatically tests the integration to your payment
                processor. If you have entered incorrect credentials,
                MemberClicks will alert you so they can be corrected before
                saving.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  successData: state.users.successData,
  user:state.users.user,
  pas:state.users.pas
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ submitQuery,getUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
