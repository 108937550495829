import React, { Component } from "react";
import Footer from "../components/footer";
import Banner from "../components/banner";
import {Link} from "react-router-dom"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUser, submitInput } from "../redux/clientConfiguration/action";



class Thanks extends Component {
  componentDidMount() {
    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     window.location.href = "/"
    //   } 
    // }
  }
  render() {
    const { client, logo, base_url, footerLogo, apiBody, submitInput, successData } = this.props || {};
    return (
      <div>
        <Banner
          title={"You're Almost Done!"}
          subtitle={"Your Merchant Identification Number has been generated along with your gateway credentials.  We just need your signature to make it official."}
        />
        <div className="container padding-bottom-3x mb-2">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-8">
              <p>
                <center>
                  We recommend that you print and/or save a copy of your{" "}
                  <b>Merchant Agreement</b> for your records.
                </center>
              </p>
              <p>
                <center>
                Your <b>Online Merchant Identification Number (MID)</b> is{" "}
                  <span className="rateFee">{successData.merchant.mid}</span>.
                </center>
              </p>
              <div className="form-footer">
                <a
                  className="btn btn-lg btn-rounded btn-primary"
                  target="_blank"
                  href={successData.applicationUrl}
                >
                  Review & Sign Agreement
                </a>
                {client.has_portal?
               <Link to="/confirmation">
                <a
                  className="btn btn-lg btn-rounded btn-secondary"
                >
                   Online Payment Processing Configuration
                </a>
                </Link>
                :null}
                {/* <div style={{ paddingTop: "20px" }}>
                  Reference ID: 443413&nbsp;&nbsp;&nbsp;
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.users.client,
  logo: state.users.logo,
  base_url: state.users.base_url,
  footerLogo: state.users.footerLogo,
  apiBody: state.users.apiBody,
  successData: state.users.successData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUser, submitInput }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (Thanks);