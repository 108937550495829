import firebase from "firebase/app";
import 'firebase/firestore';
const config = {
    apiKey: "AIzaSyAlBhtXn_2Mipbbx7vnpC4k9zx_2N8IP2s",
    authDomain: "payroc-boarding.firebaseapp.com",
    databaseURL: "https://payroc-boarding.firebaseio.com",
    projectId: "payroc-boarding",
    storageBucket: "payroc-boarding.appspot.com",
    messagingSenderId: "400791041555",
    appId: "1:400791041555:web:4294f0076b803a5432b1e6",
    measurementId: "G-CCSL6QKGV0"
}
firebase.initializeApp(config);
export const databaseRef = firebase.firestore();
//window.db = databaseRef
//export const todosRef = databaseRef.child("todos")
