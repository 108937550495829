import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import SignaturePad from 'react-signature-canvas'

import styles from '../styles.modules.css'

class Pad extends Component {
  state = {trimmedDataURL: null}
  sigPad = {}
  clear = () => {
    this.sigPad.clear()
  }
  trim = () => {
    this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
      .toDataURL('image/png')})
  }
  render () {
    let {trimmedDataURL} = this.state
    return <div className="container1">
      <div className="sigContainer">
        <SignaturePad canvasProps={{className:"sigPad" , id:"pad"}}
          ref={(ref) => { this.sigPad = ref }} />
      </div>
      {/* <div>
        <button className="buttons" onClick={this.clear}>
          Clear
        </button>
        <button className="buttons" onClick={this.trim}>
          Trim
        </button>
      </div>
      {trimmedDataURL
        ? <img className="sigImage"
          src={trimmedDataURL} />
        : null} */}
    </div>
  }
}

export default Pad

