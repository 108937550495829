export const GET_USER = "GET_USER";
export const GET_USER_DATA = "GET_USER_DATA";
export const SUCCESS_GET_USER = "SUCCESS_GET_USER";
export const ERROR_GET_USER = "ERROR_GET_USER";
export const SUBMIT_INPUT = "SUBMIT_INPUT";
export const SUCCESS_DATA = "SUCCESS_DATA";
export const SUCCESS_DATA2 = "SUCCESS_DATA2";
export const SUBMIT_QUERY = "SUBMIT_QUERY";


// GET DATA
export const getUser = id => ({ type: GET_USER, id });
export const getUserData = (data, apiVals) => ({ type: GET_USER_DATA, data, apiVals });
export const submitInput = data => ({ type: SUBMIT_INPUT, data });
export const submitQuery = id => ({ type: SUBMIT_QUERY, id });

// SUCCESS
export const successGetUser = data => ({ type: SUCCESS_GET_USER, data });
export const successData = (data,sub) => ({ type: SUCCESS_DATA, data, sub });
export const successData2 = (data, data1) => ({ type: SUCCESS_DATA2, data, data1 });

// ERROR
export const errorGetUser = data => ({ type: ERROR_GET_USER, data });


